import {
  Badge,
  Box,
  IconChevron,
  IconFilter,
  Inline,
  Text,
} from 'braid-design-system';
import type { Dispatch, SetStateAction } from 'react';

type QueryFilterDrawerActionProps = {
  setShowFilterDrawer: Dispatch<SetStateAction<boolean>>;
  filterTitle: string;
  totalFilters: number;
};
export const QueryFilterDrawerAction = ({
  setShowFilterDrawer,
  filterTitle,
  totalFilters,
}: QueryFilterDrawerActionProps) => (
  <Box
    boxShadow="borderNeutralLarge"
    borderRadius={'large'}
    padding={'medium'}
    display={'flex'}
    onClick={() => {
      setShowFilterDrawer(true);
    }}
  >
    <Inline space={'small'}>
      <Box style={{ marginTop: '-2px' }}>
        {' '}
        <IconFilter />
      </Box>

      <Box style={{ marginTop: '5px' }}>
        <Text weight="strong">{filterTitle}</Text>
      </Box>
      <Box style={{ marginTop: '2px' }}>
        <Badge tone="neutral">{totalFilters}</Badge>
      </Box>
    </Inline>
    <Box style={{ marginTop: '-2px', marginLeft: 'auto' }}>
      <IconChevron direction={'right'} />
    </Box>
  </Box>
);

import type {
  GetFilterFieldsQuery,
  GetSurveyQuery,
} from 'src/gql/tal/generated';
import type {
  Driver,
  DriverList,
  DriverOption,
} from './components/QueryFilter';
import type { FormValues } from './components/QueryFilterForm';
import type { useFields } from './components/useFields';

export interface DatalabQueryParams {
  primaryDriver: string;
  primaryDriverOptions?: string[];
  secondaryDriver?: string;
  secondaryDriverOptions?: string[];
}

export interface DatalabSurvey {
  queryFilters: GetFilterFieldsQuery;
  surveyResults: GetSurveyQuery;
  queryFilterParams: DatalabQueryParams;
}

export interface QueryFilterSelectionDisabled {
  primaryDriverOptionsDisabled: boolean;
  secondaryDriverDisabled: boolean;
  secondaryDriverOptionsDisabled: boolean;
}

const driversMap: Map<string, string> = new Map([
  ['industries', 'Industry'],
  ['genders', 'Gender'],
  ['senioritys', 'Seniority'],
  ['generations', 'Generation'],
  ['locations', 'Location'],
  ['workTypes', 'Work type'],
]);

const getQueryFilterParams = (
  queryParams: URLSearchParams,
): DatalabQueryParams => ({
  primaryDriver: queryParams.get('primaryDriver') || 'ALL',
  primaryDriverOptions: queryParams.getAll('primaryDriverOptions'),
  secondaryDriver: queryParams.get('secondaryDriver') || undefined,
  secondaryDriverOptions: queryParams.getAll('secondaryDriverOptions'),
});

const getDriverListFrom = (queryFilter: GetFilterFieldsQuery): DriverList => {
  const entries = Object.entries(queryFilter);
  const drivers = entries.map(
    ([key, values]) =>
      ({
        name: driversMap.get(key),
        options: values.map((value: DriverOption) => ({ name: value.name })),
      } as Driver),
  );

  return {
    drivers,
  };
};

interface SyncDriverList {
  primaryDrivers: DriverList;
  secondaryDrivers: DriverList;
  selectedPrimaryDriver: string;
}

const updateSecondaryDriverList = ({
  primaryDrivers,
  secondaryDrivers,
  selectedPrimaryDriver,
}: SyncDriverList) => {
  secondaryDrivers.drivers = [
    { name: '', options: [] },
    ...primaryDrivers.drivers.filter(
      (driver) =>
        driver.name !== 'ALL' && driver.name !== selectedPrimaryDriver,
    ),
  ];
};

const isFormValueValidForPrimaryDriverQueryParam = (formValues: FormValues) =>
  formValues.primaryDriverSelect &&
  (formValues.primaryDriverSelect === 'ALL' ||
    formValues.primaryDriverOptionsSelect);

const isFormValueValidForPrimaryDriverOptionsQueryParam = (
  formValues: FormValues,
) =>
  formValues.primaryDriverSelect &&
  formValues.primaryDriverSelect !== 'ALL' &&
  formValues.primaryDriverOptionsSelect;

const isFormValueValidForSecondaryDriverQueryParam = (formValues: FormValues) =>
  formValues.secondaryDriverSelect && formValues.secondaryDriverOptionsSelect;

const generateUrlQueryParamFrom = (formValues: FormValues) => {
  const primayDriver = isFormValueValidForPrimaryDriverQueryParam(formValues)
    ? `?primaryDriver=${encodeURIComponent(formValues.primaryDriverSelect)}`
    : '';
  const primaryDriverOptions =
    isFormValueValidForPrimaryDriverOptionsQueryParam(formValues)
      ? `&primaryDriverOptions=${encodeURIComponent(
          formValues.primaryDriverOptionsSelect,
        )}`
      : '';
  const secondaryDriver = isFormValueValidForSecondaryDriverQueryParam(
    formValues,
  )
    ? `&secondaryDriver=${encodeURIComponent(formValues.secondaryDriverSelect)}`
    : '';
  const secondaryDriverOptions = isFormValueValidForSecondaryDriverQueryParam(
    formValues,
  )
    ? `&secondaryDriverOptions=${encodeURIComponent(
        formValues.secondaryDriverOptionsSelect,
      )}`
    : '';

  return `${primayDriver}${primaryDriverOptions}${secondaryDriver}${secondaryDriverOptions}`;
};

const generateResultFromFilteredDriverOptionsInQueryParam = (
  queryFilterParams: DatalabQueryParams,
): string =>
  (queryFilterParams.primaryDriverOptions || [])
    .concat(queryFilterParams.secondaryDriverOptions || [])
    .join(', ');

const generateDriverSummaryHeadingFrom = (
  filteredDriverOptions: string | undefined,
): {
  hasDriverOptions: boolean;
  title: 'Showing results that match' | 'Showing results from';
} => {
  const hasDriverOptions: boolean =
    filteredDriverOptions !== undefined &&
    filteredDriverOptions !== '' &&
    filteredDriverOptions.toUpperCase() !== 'ALL';
  const title = hasDriverOptions
    ? `Showing results that match`
    : `Showing results from`;
  return { hasDriverOptions, title };
};

const getQueryFilterSelectionDisabledStateFrom = ({
  primaryDriverSelect,
  secondaryDriverSelect,
}: ReturnType<typeof useFields>): QueryFilterSelectionDisabled => {
  const primaryDriverOptionsDisabled: boolean =
    primaryDriverSelect.value === '' || primaryDriverSelect.value === 'ALL';
  const secondaryDriverDisabled: boolean = primaryDriverOptionsDisabled;
  const secondaryDriverOptionsDisabled: boolean =
    primaryDriverOptionsDisabled || secondaryDriverSelect.value === '';
  return {
    primaryDriverOptionsDisabled,
    secondaryDriverDisabled,
    secondaryDriverOptionsDisabled,
  };
};

export const dataLabHelper = {
  getDriverListFrom,
  getQueryFilterParams,
  updateSecondaryDriverList,
  generateUrlQueryParamFrom,
  generateResultFromFilteredDriverOptionsInQueryParam,
  generateDriverSummaryHeadingFrom,
  getQueryFilterSelectionDisabledStateFrom,
};

import { createForm } from '@seek/forms-ui';
import { QueryFilter, type DriverList } from './QueryFilter';
import type { DatalabQueryParams } from '../datalabHelper';

export interface FormValues {
  primaryDriverSelect: string;
  // the type might need to be changed here
  primaryDriverOptionsSelect: string;
  secondaryDriverSelect: string;
  secondaryDriverOptionsSelect: string;
}

const { FormProvider, useField } = createForm<FormValues>();
export { useField };

interface Props {
  driverList: DriverList;
  queryFilterParams: DatalabQueryParams;
}

export const QueryFilterForm = ({ driverList, queryFilterParams }: Props) => {
  const initialValues: FormValues = {
    primaryDriverSelect: queryFilterParams.primaryDriver,
    primaryDriverOptionsSelect: queryFilterParams.primaryDriverOptions
      ? queryFilterParams.primaryDriverOptions[0]
      : '',
    secondaryDriverSelect: queryFilterParams.secondaryDriver
      ? queryFilterParams.secondaryDriver
      : '',
    secondaryDriverOptionsSelect: queryFilterParams.secondaryDriverOptions
      ? queryFilterParams.secondaryDriverOptions[0]
      : '',
  };

  return (
    <FormProvider initialValues={initialValues}>
      {({ handleSubmit, resetForm, setFieldValue }) => {
        const clearPrimaryDriverOptions = () =>
          setFieldValue('primaryDriverOptionsSelect', '');
        const clearSecondaryDriver = () =>
          setFieldValue('secondaryDriverSelect', '');
        const clearSecondaryDriverOptions = () =>
          setFieldValue('secondaryDriverOptionsSelect', '');

        return (
          <QueryFilter
            driverList={driverList}
            handleSubmit={handleSubmit}
            resetForm={resetForm}
            clearPrimaryDriverOptions={clearPrimaryDriverOptions}
            clearSecondaryDriver={clearSecondaryDriver}
            clearSecondaryDriverOptions={clearSecondaryDriverOptions}
          />
        );
      }}
    </FormProvider>
  );
};

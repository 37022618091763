import type {
  CustomHeader,
  LinkFragment,
  Locale,
} from '@seek/cmsu-cms-connect';
import type { Country, Language } from '@seek/melways-sites';
import { useTranslations } from '@vocab/react';
import React from 'react';
import {
  Box,
  Column,
  Columns,
  Divider,
  Hidden,
  IconChevron,
  PageBlock,
  Spread,
  Stack,
  Text,
} from 'braid-design-system';

import { Logo } from './components/Logo';
import { LanguageToggle } from './components/LanguageToggle';
import { NavLinkGroup } from './components/NavLinkGroup';
import { convertLinks, getFallbackSeekLogo } from './helpers';
import { MobileMenu } from './components/MobileMenu';
import * as styles from './styles.css';
import { AnzSiteNav } from './components/AnzSiteNav';
import { CustomHeaderProvider } from './CustomHeaderProvider';

import { toUrl } from '../../utils';
import translations from '../../.vocab';
import { BurgerMenu } from './components/BurgerMenu';

interface Props
  extends Partial<Pick<CustomHeader, 'divider' | 'links' | 'logo'>> {
  alignLinks?: 'left' | 'right';
  language: Language;
  country: Country;
  locale: Locale;
  route: string[];
  siteNav?: boolean;
  theme?: 'default' | 'sara' | 'star';
}

export const CustomPageHeader = ({
  alignLinks,
  country,
  divider,
  route,
  logo,
  links,
  language,
  locale,
  siteNav = true,
  theme = 'default',
}: Props) => {
  const { t } = useTranslations(translations);
  const [menuOpen, toggleMenuOpen] = React.useState(false);
  const showSiteNav = (country === 'au' || country === 'nz') && siteNav;
  const activeRoute = route.join('/');
  const path = activeRoute.split(`/${country}`).pop() || activeRoute;
  const formattedLinks = links && convertLinks(links, language, locale);
  const logoData = logo || getFallbackSeekLogo(locale);

  return (
    <CustomHeaderProvider
      config={{
        showSiteNav,
        language,
        country,
        path,
        activeRoute,
        links: formattedLinks,
        theme,
      }}
    >
      <Box width="full" background="surface" data={{ testid: 'custom-header' }}>
        {showSiteNav && (
          <Hidden below="tablet">
            <AnzSiteNav />
          </Hidden>
        )}
        <Box
          background="surface"
          paddingY={{ mobile: 'small', tablet: 'xxsmall' }}
          className={[styles.menu, menuOpen ? styles.menuOpen : '']}
          width="full"
          height="full"
        >
          <Stack space="xxsmall">
            <PageBlock width="large">
              <Columns space="small" alignY="top">
                <Column>
                  <Columns
                    space={{
                      mobile: 'small',
                      tablet: 'none',
                      desktop: 'gutter',
                    }}
                    alignY="top"
                    collapseBelow="desktop"
                  >
                    {/* Logo image */}
                    <Column width="content">
                      <Box>
                        <Logo
                          src={logoData.image.url}
                          alt={logoData.title || ''}
                          href={toUrl(
                            logoData.link?.to as LinkFragment['to'],
                            language,
                          )}
                          size={{
                            width: logoData.image.width as number,
                            height: logoData.image.height as number,
                          }}
                        />
                      </Box>
                    </Column>
                    {/* Nav Links */}
                    <Column hideBelow="tablet">
                      <Spread
                        space="medium"
                        alignY="center"
                        direction="horizontal"
                      >
                        {formattedLinks && (
                          <NavLinkGroup
                            alignLinks={alignLinks}
                            links={formattedLinks}
                            activeRoute={activeRoute}
                          />
                        )}
                      </Spread>
                    </Column>
                  </Columns>
                </Column>
                {/* Mobile menu button */}
                <Column width="content" hideAbove="mobile">
                  {theme === 'sara' ? (
                    <BurgerMenu
                      open={menuOpen}
                      onClick={() => toggleMenuOpen(!menuOpen)}
                    />
                  ) : (
                    <Box
                      paddingX={{ mobile: 'none', tablet: 'gutter' }}
                      paddingY="none"
                    >
                      <Box
                        component="button"
                        cursor="pointer"
                        arial-label="Menu"
                        aria-expanded={menuOpen}
                        onClick={() => toggleMenuOpen(!menuOpen)}
                      >
                        <Spread
                          space="xxsmall"
                          alignY="center"
                          direction="horizontal"
                        >
                          <Text>{t('Menu')}</Text>
                          <IconChevron
                            size="small"
                            direction={menuOpen ? 'up' : 'down'}
                          />
                        </Spread>
                      </Box>
                    </Box>
                  )}
                </Column>
                {/* Language toggle */}
                <Column width="content" hideBelow="tablet">
                  <Box paddingY="small">
                    <LanguageToggle />
                  </Box>
                </Column>
              </Columns>
            </PageBlock>
            {divider && (
              <Hidden below="tablet">
                <Divider />
              </Hidden>
            )}
          </Stack>
          {/* Mobile menu */}
          <Hidden above="mobile">
            <Box paddingTop="small">
              <Divider />
            </Box>
            <MobileMenu open={menuOpen} />
          </Hidden>
        </Box>
      </Box>
    </CustomHeaderProvider>
  );
};

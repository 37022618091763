import type {
  BannerAwardFragment,
  BannerPrimaryFragment,
  BannerShopfrontFragment,
} from '@seek/cmsu-cms-connect';
import type { Country } from '@seek/melways-sites';

import type { FindByTypename } from '../FindByTypename';
import { universal } from './renderers/universal';

/**
 * Union of all Banner types
 */
type Banner =
  | BannerPrimaryFragment
  | BannerShopfrontFragment
  | BannerAwardFragment;

/**
 * Returns the Type given the __typename
 */
type BannerOf<Type extends Banner['__typename']> = FindByTypename<Banner, Type>;

export type BannerRenderers = Partial<{
  [Property in Banner['__typename']]: (
    banner: Omit<BannerOf<Property>, '__typename'>,
    country: Country,
    theme: 'default' | 'sara' | 'star',
  ) => JSX.Element;
}>;

type Props = {
  banner: Banner;
  country?: Country;
  renderers?: BannerRenderers;
  theme?: 'default' | 'sara' | 'star';
};

/**
 * Matches a Banner to the given renders
 */
export const BannerRenderer = ({
  renderers = universal,
  banner,
  country = 'au',
  theme = 'default',
}: Props) =>
  renderers[banner.__typename]?.(
    // Setting `banner` as any to suppress `never` errors
    banner as any,
    country,
    theme,
  ) || null;

import { useTranslations } from '@vocab/react';
import {
  Box,
  Button,
  Dropdown,
  Hidden,
  Inline,
  Stack,
} from 'braid-design-system';
import translations from '../../../.vocab';
import translations_tal from '../.vocab';
import { QueryFilterHeading } from './QueryFilterHeading';
import { useFields } from './useFields';
import { dataLabHelper } from '../datalabHelper';
import { useMemo } from 'react';
import type { FormValues } from './QueryFilterForm';

interface FilterProp {
  filterLabel?: string;
  filterPlaceholder?: string;
}

interface DriverFilterProp extends FilterProp {
  optionFilter: FilterProp;
}

export interface DriverOption {
  name: string;
}
export interface Driver {
  name: string;
  options: DriverOption[];
}
export interface DriverList {
  drivers: Driver[];
}

interface Props {
  driverList: DriverList;
  handleSubmit: any;
  resetForm: any;
  clearPrimaryDriverOptions: any;
  clearSecondaryDriver: any;
  clearSecondaryDriverOptions: any;
}

export const QueryFilter = ({
  driverList,
  handleSubmit,
  resetForm,
  clearPrimaryDriverOptions,
  clearSecondaryDriver,
  clearSecondaryDriverOptions,
}: Props) => {
  const { t } = useTranslations(translations);
  const { t: t_tal } = useTranslations(translations_tal);

  const primaryDriverList: DriverList = useMemo(
    () => ({
      drivers: [{ name: 'ALL', options: [] }, ...driverList.drivers],
    }),
    [driverList],
  );

  const secondaryDriverList: DriverList = useMemo(
    () => ({
      drivers: [{ name: '', options: [] }, ...driverList.drivers],
    }),
    [driverList],
  );

  const primaryDriverFilter: DriverFilterProp = {
    filterLabel: t_tal('Explore drivers by'),
    filterPlaceholder: t('Select a filter'),
    optionFilter: {
      filterLabel: t('Select up to two options'),
      filterPlaceholder: t('Select options'),
    },
  };

  const secondaryDriverFilter: DriverFilterProp = {
    filterLabel: t('Filter by'),
    filterPlaceholder: t('Select a filter'),
    optionFilter: {
      filterLabel: t('Select up to two options'),
      filterPlaceholder: t('Select options'),
    },
  };

  const formFields = useFields();
  const {
    primaryDriverSelect,
    primaryDriverOptionsSelect,
    secondaryDriverSelect,
    secondaryDriverOptionsSelect,
  } = formFields;

  useMemo(() => {
    dataLabHelper.updateSecondaryDriverList({
      primaryDrivers: primaryDriverList,
      secondaryDrivers: secondaryDriverList,
      selectedPrimaryDriver: primaryDriverSelect.value,
    });
    if (
      secondaryDriverSelect.value &&
      !secondaryDriverList.drivers.find(
        (driver) => driver.name === secondaryDriverSelect.value,
      )
    ) {
      secondaryDriverSelect.value = '';
      secondaryDriverOptionsSelect.value = '';
    }
  }, [
    primaryDriverList,
    secondaryDriverList,
    primaryDriverSelect.value,
    secondaryDriverSelect,
    secondaryDriverOptionsSelect,
  ]);

  const {
    primaryDriverOptionsDisabled,
    secondaryDriverDisabled,
    secondaryDriverOptionsDisabled,
  } = dataLabHelper.getQueryFilterSelectionDisabledStateFrom(formFields);

  if (primaryDriverOptionsDisabled) clearPrimaryDriverOptions();
  if (secondaryDriverDisabled) clearSecondaryDriver();
  if (secondaryDriverOptionsDisabled) clearSecondaryDriverOptions();

  const onSubmit = handleSubmit((formValues: FormValues) => {
    window.location.href = dataLabHelper.generateUrlQueryParamFrom(formValues);
  });

  return (
    <form onSubmit={onSubmit} style={{ width: '100%' }}>
      <Box style={{ minWidth: '270px', maxWidth: '405px' }}>
        <Stack space="medium">
          <Hidden below="tablet">
            <QueryFilterHeading
              title={t('Filters')}
              description={t_tal(
                'Select filter to uncover deeper insights about candidates',
              )}
            />
          </Hidden>

          {/* PRIMARY */}
          <DriverFilter
            driver={primaryDriverSelect}
            driverList={primaryDriverList}
            driverOptions={primaryDriverOptionsSelect}
            filterProp={primaryDriverFilter}
            driverDisabled={false}
            driverOptionDisabled={primaryDriverSelect.value === 'ALL'}
          />

          {/* SECONDARY */}
          <DriverFilter
            driver={secondaryDriverSelect}
            driverList={secondaryDriverList}
            driverOptions={secondaryDriverOptionsSelect}
            filterProp={secondaryDriverFilter}
            driverDisabled={secondaryDriverDisabled}
            driverOptionDisabled={secondaryDriverOptionsDisabled}
          />

          <Inline space="small" collapseBelow="tablet">
            <Button variant="solid" type="submit">
              {t('Apply filters')}
            </Button>
            <Button variant="transparent" type="button" onClick={resetForm}>
              {t('Clear all')}
            </Button>
          </Inline>
        </Stack>
      </Box>
    </form>
  );
};

interface DriverFilterProps {
  driver: any;
  driverList: DriverList;
  driverOptions: any;
  filterProp: DriverFilterProp;
  driverDisabled: boolean;
  driverOptionDisabled: boolean;
}

const DriverFilter = ({
  filterProp,
  driverList,
  driverDisabled,
  driverOptionDisabled,
  driverOptions,
  driver,
}: DriverFilterProps) => {
  const options = driverList.drivers.find(
    (d) => d.name === driver.value,
  )?.options;

  return (
    <Stack space="medium">
      <Dropdown
        id="PRIMARY_FILTER"
        label={filterProp.filterLabel}
        onChange={driver.onChange}
        placeholder={filterProp.filterPlaceholder}
        value={!driverDisabled ? driver.value : ''}
        disabled={driverDisabled}
        tone={!driver.valid ? 'critical' : 'neutral'}
        message={!driver.valid && driver.errorMessage}
      >
        <>
          {driverList.drivers.map((d) => (
            <option key={d.name} value={d.name}>
              {d.name}
            </option>
          ))}
        </>
      </Dropdown>

      <Dropdown
        id="PRIMARY_OPTION_FILTER"
        label={filterProp.optionFilter.filterLabel}
        onChange={driverOptions.onChange}
        placeholder={filterProp.optionFilter.filterPlaceholder}
        value={!driverOptionDisabled ? driverOptions.value : ''}
        disabled={driverOptionDisabled}
        tone={!driverOptions.valid ? 'critical' : 'neutral'}
        message={!driverOptions.valid && driverOptions.errorMessage}
      >
        <>
          {options &&
            options.map((option) => (
              <option key={option.name} value={option.name}>
                {option.name}
              </option>
            ))}
        </>
      </Dropdown>
    </Stack>
  );
};

import { CMSUProvider } from '@seek/cmsu-components/src/hooks/useCMSUContext';
import {
  Box,
  Column,
  Columns,
  ContentBlock,
  Hidden,
  PageBlock,
  Stack,
  Text,
  ToastProvider,
} from 'braid-design-system';
import { useState, type Dispatch, type SetStateAction } from 'react';
import { useLoaderData } from 'react-router';
import type { GetSurveyQuery } from '../../../gql/tal/generated';
import { PageLayout } from '../../components/PageLayout/PageLayout';
import { useConfig } from '../../shared/hooks';
import { useTargetBlank } from '../../shared/hooks/useTargetBlank';
import type { LayoutProps } from '../../shared/types';
import { DriverSummary } from './components/DriverSummary/DriverSummary';
import type { DriverList } from './components/QueryFilter';
import { QueryFilterDrawer } from './components/QueryFilterDrawer';
import { QueryFilterForm } from './components/QueryFilterForm';
import {
  dataLabHelper,
  type DatalabQueryParams,
  type DatalabSurvey,
} from './datalabHelper';

// TODO!: Replace with actual page title
const pageTitle = 'Talent Acquisition Lab';
const layoutProps: LayoutProps = {
  title: 'Loading...',
};

export const Datalab = () => {
  useTargetBlank();
  // Get the params from context.
  const {
    // melwaysLocale, fullURL, sourceName, privacyUrl, country,
    fullURL,

    language,
  } = useConfig();

  const { queryFilters, surveyResults, queryFilterParams } =
    useLoaderData() as DatalabSurvey;

  const driverList: DriverList = dataLabHelper.getDriverListFrom(queryFilters);
  const [showFilterDrawer, setShowDrawerFilter] = useState<boolean>(false);

  return (
    <PageLayout
      layoutProps={{
        ...layoutProps,
        title: pageTitle,
      }}
    >
      <CMSUProvider config={{ language }}>
        <ToastProvider>
          <PageBlock width="large">
            <ContentBlock width="large">
              <Box paddingY={'large'}>
                <Columns
                  space={{ mobile: 'medium', tablet: 'xlarge' }}
                  collapseBelow="tablet"
                >
                  <Column width="content">
                    <DatalabNavigation
                      driverList={driverList}
                      queryFilterParams={queryFilterParams}
                      setShowDrawerFilter={setShowDrawerFilter}
                      showFilterDrawer={showFilterDrawer}
                    />
                  </Column>

                  <Column>
                    <Box>
                      <DatalabResult
                        fullURL={fullURL}
                        queryFilterParams={queryFilterParams}
                        surveyResults={surveyResults}
                      />
                    </Box>
                  </Column>
                </Columns>
              </Box>
            </ContentBlock>
          </PageBlock>
        </ToastProvider>
      </CMSUProvider>
    </PageLayout>
  );
};

interface DatalabNavigationProps {
  driverList: DriverList;
  queryFilterParams: DatalabQueryParams;
  setShowDrawerFilter: Dispatch<SetStateAction<boolean>>;
  showFilterDrawer: boolean;
}

const DatalabNavigation = ({
  driverList,
  queryFilterParams,
  setShowDrawerFilter,
  showFilterDrawer,
}: DatalabNavigationProps) => (
  <>
    <Hidden below="tablet">
      <Box display={'flex'} flexShrink={0} style={{ width: '350px' }}>
        <QueryFilterForm
          driverList={driverList}
          queryFilterParams={queryFilterParams}
        />
      </Box>
    </Hidden>
    <Hidden above="mobile">
      <QueryFilterDrawer
        driverList={driverList}
        queryFilterParams={queryFilterParams}
        setShowFilterDrawer={setShowDrawerFilter}
        showFilterDrawer={showFilterDrawer}
      />
    </Hidden>
  </>
);

interface DatalabResultProps {
  fullURL: string;
  queryFilterParams: DatalabQueryParams;
  surveyResults: GetSurveyQuery;
}

const DatalabResult = ({
  fullURL,
  queryFilterParams,
  surveyResults,
}: DatalabResultProps) => {
  const filteredDriverOptions: string =
    dataLabHelper.generateResultFromFilteredDriverOptionsInQueryParam(
      queryFilterParams,
    );
  return (
    <Box>
      <DriverSummary
        pageUrl={fullURL}
        pageTitle={pageTitle}
        filteredDriverOptions={filteredDriverOptions}
        respondentCount={surveyResults.respondents}
        candidateCount={surveyResults.demographic.respondants}
      />
      <Box marginTop={'xlarge'}>
        <Stack space={'gutter'}>
          <Text size="large" weight="strong">
            Drivers that attract these candidates
          </Text>
          <Text>
            These are the drivers candidates consider when looking at roles.
            Drivers are ranked with the most important driver at the top. Expand
            each driver for more detail.
          </Text>
        </Stack>
      </Box>
    </Box>
  );
};

import {
  Box,
  ButtonIcon,
  Heading,
  IconDownload,
  Inline,
  Spread,
  Stack,
  Text,
  TextLink,
  TextLinkButton,
  TooltipRenderer,
} from 'braid-design-system';
import { ShareDropdown } from '../ShareDropdown/ShareDropdown';
import { dataLabHelper } from '../../datalabHelper';
import { useTranslations } from '@vocab/react';
import translations_tal from '../../.vocab';
// TODO!: Add a download button url
// TODO!: Add about navigation link

interface QuerySummaryProps {
  filteredDriverOptions: string;
  respondentCount: number;
  candidateCount: number;
  pageUrl: string;
  pageTitle: string;
}

const DownloadButton = () => (
  <ButtonIcon
    size="large"
    icon={<IconDownload />}
    id="download-pdf-button"
    label="Download PDF"
    variant="soft"
  />
);

const ShareButton = ({
  pageUrl,
  pageTitle,
}: {
  pageUrl: string;
  pageTitle: string;
}) => <ShareDropdown pageUrl={pageUrl} title={pageTitle} />;

const RespondentInfo = ({
  respondentCount,
  candidateCount,
}: {
  respondentCount: number;
  candidateCount: number;
}) => (
  <Text>
    Results are based on{' '}
    <TooltipRenderer
      id="respondent-tooltip"
      tooltip={
        <Text>
          This sample size is statistically significant for the filters you have
          chosen
        </Text>
      }
    >
      {({ triggerProps }) => (
        <Box {...triggerProps} display="inline">
          <TextLinkButton>{respondentCount.toLocaleString()}</TextLinkButton>
        </Box>
      )}
    </TooltipRenderer>{' '}
    respondents from a total of{' '}
    <TextLink href={''}>
      {candidateCount.toLocaleString()} candidates surveyed
    </TextLink>{' '}
    January 2023 - February 2024.
  </Text>
);

const DriverSummaryTitle: React.FC<{ filteredDriverOptions: string }> = ({
  filteredDriverOptions,
}) => {
  const { t: t_tal } = useTranslations(translations_tal);
  const { title } = dataLabHelper.generateDriverSummaryHeadingFrom(
    filteredDriverOptions,
  );
  return <Text>{t_tal(title)}</Text>;
};

const DriverSummaryAction: React.FC<{ pageUrl: string; pageTitle: string }> = ({
  pageUrl,
  pageTitle,
}) => (
  <Inline space={'small'}>
    <DownloadButton />
    <ShareButton pageUrl={pageUrl} pageTitle={pageTitle} />
  </Inline>
);

type DriverSummaryHeaderProps = {
  children: Parameters<typeof Spread>[0]['children'];
};

const DriverSummaryHeader: React.FC<DriverSummaryHeaderProps> = ({
  children,
}) => (
  <Spread space={'large'} alignY={'center'}>
    {children}
  </Spread>
);

const DriverSummaryResult: React.FC<{
  filteredDriverOptions: string;
  respondentCount: number;
  candidateCount: number;
}> = ({ filteredDriverOptions, respondentCount, candidateCount }) => {
  const { hasDriverOptions } = dataLabHelper.generateDriverSummaryHeadingFrom(
    filteredDriverOptions,
  );
  const { t: t_tal } = useTranslations(translations_tal);

  return (
    <Stack space={'gutter'}>
      <Heading level={'3'}>
        ‘{hasDriverOptions ? filteredDriverOptions : t_tal('All respondents')}’
      </Heading>

      <RespondentInfo
        respondentCount={respondentCount}
        candidateCount={candidateCount}
      />
    </Stack>
  );
};

type DriverSummaryContentProps = {
  children: Parameters<typeof Stack>[0]['children'];
};

const DriverSummaryContent: React.FC<DriverSummaryContentProps> = ({
  children,
}) => <Stack space={{ mobile: 'gutter', desktop: 'medium' }}>{children}</Stack>;

type DriverSummaryContainerProps = {
  children: Parameters<typeof Stack>[0]['children'];
};

const DriverSummaryContainer: React.FC<DriverSummaryContainerProps> = ({
  children,
}) => <Stack space={{ mobile: 'large', tablet: 'medium' }}>{children}</Stack>;

const DriverSummary: React.FC<QuerySummaryProps> = ({
  filteredDriverOptions,
  respondentCount,
  candidateCount,
  pageUrl,
  pageTitle,
}) => (
  <DriverSummaryContainer>
    <DriverSummaryHeader>
      <DriverSummaryTitle filteredDriverOptions={filteredDriverOptions} />
      <DriverSummaryAction pageUrl={pageUrl} pageTitle={pageTitle} />
    </DriverSummaryHeader>
    <DriverSummaryContent>
      <DriverSummaryResult
        filteredDriverOptions={filteredDriverOptions}
        respondentCount={respondentCount}
        candidateCount={candidateCount}
      />
    </DriverSummaryContent>
  </DriverSummaryContainer>
);

export { DriverSummary };
